import React from "react"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Customers from "../components/customers"

const Showcase = ({ data }) => (
	<Layout>
		<SEO
			title="Automated Image Blurring Showcase"
			description="See what our automated blurring technology is capable of."
		/>
		<section className="pt-4 pt-md-11">
			<div className="container">
				<div className="row justify-content-center">
					<div className="col-12 col-md-10 col-lg-9 col-xl-8">
						<h1 className="display-4 text-center">
							Celantur <span className="text-primary">Showcase</span>
						</h1>
						<p className="lead mb-7 text-center text-muted">
							Explore real life examples of images and videos anonymized with Celantur technology.
						</p>
					</div>
				</div>
			</div>
		</section>

		<section className="pb-8 pt-7 pb-md-11 pt-md-10">
			<div className="container">
				<div className="row">
					<div className="col-12 d-flex">
						<div className="card shadow-lift mb-6">
							<div className="card-img-top" style={{ height: "auto" }}>
								<Img
									fluid={data.beirut1.childImageSharp.fluid}
									style={{ maxWidth: "100%", height: "100%" }}
									className="card-img-top"
									alt="Face and license plate anonymization in Beirut"
								/>
							</div>
							<div className="card-body">
								<h3>Face and license plate anonymization in Beirut</h3>
								<p className="text-muted mr-2 mb-0">
									After the Beirut Explosion in Summer 2020, the American University of Beirut started to collect
									street-level images for damage assessment. Celantur provided the technology for making the datasets
									privay-compliant.
								</p>
								<a href="https://app.celantur.com/" className="btn btn-primary-soft btn-sm event-start-demo mr-3">
									Start Demo
								</a>
								<Link
									to="/success-story-american-university-beirut/"
									role="button"
									className="btn btn-sm btn-link my-2 mr-3"
								>
									Read Success Story
								</Link>
								<Link to="/contact/" role="button" className="btn btn-sm btn-link my-2 event-contact">
									Contact Us
								</Link>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>

		<section className="pb-8 pt-7 pb-md-11 pt-md-10 bg-gray-800">
			<div className="container">
				<div className="row justify-content-center">
					<div className="col-12 col-md-10 col-lg-8 text-center">
						<h2 className="font-weight-bold text-white">OrbitGT &amp; Celantur</h2>
						<p className="lead mb-7 text-center text-muted">
							Use Celantur to make your OrbitGT datasets privacy-compliant by blurring personal data.
						</p>
						<div className="text-center m-3">
							<a href="https://app.celantur.com/" className="btn btn-primary btn-sm event-start-demo mr-3">
								Start Demo
							</a>
							<Link
								to="/mobile-mapping-surveying-data-anonymization/"
								role="button"
								className="btn btn-sm btn-secondary my-2 mr-3"
							>
								See Mobile Mapping Use Case
							</Link>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-12 d-flex">
						<iframe
							id="bentley-orbitgt-celantur-demo"
							src="https://3dmapping.cloud/publication/kxMiIXxJHkPbnEcV9RA2"
							width="100%"
							height="700px"
							frameBorder="0"
							title="bentley-orbitgt-celantur-demo"
						></iframe>
					</div>
				</div>
			</div>
		</section>

		<section className="pb-8 pt-7 pb-md-11 pt-md-10">
			<div className="container">
				<div className="row">
					<div className="col-12 d-flex">
						<div className="card shadow-lift mb-6">
							<div className="card-img-top" style={{ height: "auto" }}>
								<Img
									fluid={data.rgbCarsBodies.childImageSharp.fluid}
									style={{ maxWidth: "100%", height: "100%" }}
									className="card-img-top"
									alt="Mobile Mapping Street-Level Panorama, with blurred bodies and vehicles"
								/>
							</div>
							<div className="card-body">
								<h3>STRABAG AG's anonymized mobile mapping dataset from Regensburg, Germany</h3>
								<p className="text-muted mr-2 mb-0">
									STRABAG AG is one of the largest construction companies in Europe. They use Celantur to make mobile
									mapping panorama images privacy-compliant. Image Ⓒ STRABAG AG
								</p>
								<a href="https://app.celantur.com/" className="btn btn-primary-soft btn-sm event-start-demo mr-3">
									Start Demo
								</a>
								<Link
									to="/mobile-mapping-surveying-data-anonymization/"
									role="button"
									className="btn btn-sm btn-link my-2 mr-3"
								>
									See Mobile Mapping Use Case
								</Link>
								<Link to="/contact/" role="button" className="btn btn-sm btn-link my-2 event-contact">
									Contact Us
								</Link>
							</div>
						</div>
					</div>
				</div>

				<div className="row">
					<div className="col-12 d-flex">
						<div className="card shadow-lift mb-6">
							<div className="card-img-top" style={{ height: "auto" }}>
								<Img
									fluid={data.oicEntry.childImageSharp.fluid}
									style={{ maxWidth: "100%", height: "100%" }}
									className="card-img-top"
									alt="Blurred persons on images captured with GeoSLAM ZEB Discovery"
								/>
							</div>
							<div className="card-body">
								<h3>Blurred persons on images captured with GeoSLAM ZEB Discovery</h3>
								<p className="text-muted">(c) Open Innovation Center GmbH</p>
								<a href="https://app.celantur.com/" className="btn btn-primary-soft btn-sm event-start-demo mr-3">
									Start Demo
								</a>
								<Link
									to="/mobile-mapping-surveying-data-anonymization/"
									role="button"
									className="btn btn-sm btn-link my-2 mr-3"
								>
									See Mobile Mapping Use Case
								</Link>
								<Link to="/contact/" role="button" className="btn btn-sm btn-link my-2 event-contact">
									Contact Us
								</Link>
							</div>
						</div>
					</div>
				</div>

				<div className="row">
					<div className="col-12 d-flex">
						<div className="card shadow-lift mb-6">
							<div className="card-img-top" style={{ height: "auto" }}>
								<Img
									fluid={data.celanturCaliforniaWhole.childImageSharp.fluid}
									style={{ maxWidth: "100%", height: "100%" }}
									className="card-img-top"
									alt="Blurred vehicles on a suburban street in the USA"
								/>
							</div>
							<div className="card-body">
								<h3>Blurred vehicles on a suburban street in the USA</h3>
								<a href="https://app.celantur.com/" className="btn btn-primary-soft btn-sm event-start-demo mr-3">
									Start Demo
								</a>
								<Link
									to="/mobile-mapping-surveying-data-anonymization/"
									role="button"
									className="btn btn-sm btn-link my-2 mr-3"
								>
									See Mobile Mapping Use Case
								</Link>
								<Link to="/contact/" role="button" className="btn btn-sm btn-link my-2 event-contact">
									Contact Us
								</Link>
							</div>
						</div>
					</div>
				</div>

				<div className="row">
					<div className="col-12 d-flex">
						<div className="card shadow-lift mb-6">
							<div className="card-img-top" style={{ height: "auto" }}>
								<Img
									fluid={data.oicParking2.childImageSharp.fluid}
									style={{ maxWidth: "100%", height: "100%" }}
									className="card-img-top"
									alt="Blurred license plates on images captured with GeoSLAM ZEB Discovery"
								/>
							</div>
							<div className="card-body">
								<h3>Blurred license plates on images captured with GeoSLAM ZEB Discovery</h3>
								<p className="text-muted">(c) Open Innovation Center GmbH</p>
								<a href="https://app.celantur.com/" className="btn btn-primary-soft btn-sm event-start-demo mr-3">
									Start Demo
								</a>
								<Link
									to="/mobile-mapping-surveying-data-anonymization/"
									role="button"
									className="btn btn-sm btn-link my-2 mr-3"
								>
									See Mobile Mapping Use Case
								</Link>
								<Link to="/contact/" role="button" className="btn btn-sm btn-link my-2 event-contact">
									Contact Us
								</Link>
							</div>
						</div>
					</div>
				</div>

				<div className="row">
					<div className="col-12 col-md-6 d-flex">
						<div className="card shadow-light-lg mb-6">
							<div className="card-img-top" style={{ height: "auto" }}>
								<video width="100%" height="100%" playsInline muted autoPlay loop play>
									<source src="/videos/cctv_oic_face.mkv" type="video/mp4" />
									Your browser does not support the video tag.
								</video>
							</div>
							<div className="card-body">
								<h3>Surveillance/CCTV Footage</h3>
							</div>
						</div>
					</div>
					<div className="col-12 col-md-6 d-flex">
						<div className="card shadow-light-lg mb-6">
							<div className="card-img-top" style={{ height: "auto" }}>
								<video width="100%" height="100%" playsInline muted autoPlay loop play>
									<source src="/videos/cctv_oic_body.mkv" type="video/mp4" />
									Your browser does not support the video tag.
								</video>
							</div>
							<div className="card-body">
								<h3>Surveillance/CCTV Footage</h3>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>

		<section className="container my-5 text-center">
			{" "}
			{/* CTA */}
			<Link to="/contact/" role="button" className="btn btn-lg btn-success my-2 event-contact">
				Contact Us
			</Link>
			<p className="small">We speak English, Deutsch, Italiano and 汉语!</p>
		</section>

		<section className="py-8 py-md-11">
			<div className="container">
				<Customers />
			</div>
		</section>
	</Layout>
)

export default Showcase

export const logo = graphql`
	fragment imageShowcase on File {
		childImageSharp {
			fluid(maxWidth: 1200, quality: 70) {
				...GatsbyImageSharpFluid
			}
		}
	}
`

export const query = graphql`
	query {
		rgbCarsBodies: file(relativePath: { eq: "rgb-city-cars-bodies.png" }) {
			...imageShowcase
		}
		beirut1: file(relativePath: { eq: "beirut-1.png" }) {
			...imageShowcase
		}
		celanturCaliforniaWhole: file(relativePath: { eq: "celantur-california-whole.jpg" }) {
			...imageShowcase
		}
		oicParking2: file(relativePath: { eq: "oic-parking-2.jpg" }) {
			...imageShowcase
		}
		oicEntry: file(relativePath: { eq: "oic-outside-bodies.jpg" }) {
			...imageShowcase
		}
	}
`
